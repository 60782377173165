var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","appear":""}},[_c('div',{directives:[{name:"touch",rawName:"v-touch:longtap",value:(_vm.openCompare),expression:"openCompare",arg:"longtap"}],staticClass:"compare-calendar-card",class:{
            'selected'      : _vm.isSelected,
            'compare'       : _vm.compareIsOpen,
            'open-compare-animation' : _vm.compareAnimate && !_vm.compareIsOpen ,
            'close-compare-animation': !_vm.compareAnimate && _vm.compareIsOpen ,
        },style:({
            top: ((_vm.getPosition()) + "px"),
            height: (_vm.slotHeight + "px"),
        }),attrs:{"id":"compare-card-" + _vm.eventSlot.index},on:{"click":_vm.staticClickHandler}},[_c('div',{staticClass:"varnish"}),(!_vm.isSelected && !_vm.compareAnimate || _vm.isSelected && _vm.compareAnimate)?_c('div',{staticClass:"middle__info time"},[_vm._v(" "+_vm._s(_vm.time)+" ")]):_vm._e(),(_vm.isSelected && !_vm.compareAnimate)?_c('div',{staticClass:"big-info"},[_c('div',{staticClass:"card__header"},[_c('div',{staticClass:"slot__title",style:({
                    'max-height': ((_vm.slotHeight > 35 ? _vm.slotHeight - 12 - 8 : 30) + "px") 
                })},[_vm._v(" "+_vm._s(_vm.eventSlot.title || ("Занятие " + (this.index)))+" ")]),_c('div',{staticClass:"time"},[_c('div',[_vm._v(_vm._s(_vm.time))])])]),(_vm.slotHeight > 35)?_c('div',{staticClass:"info"},[_c('div',{staticClass:"trainer"},[_vm._v(" "+_vm._s(_vm.eventSlot.state.insertIndex)+" ")]),(_vm.eventSlot.settings.maxAttenders)?_c('div',[_vm._v(" 0/"+_vm._s(_vm.eventSlot.settings.maxAttenders)+" ")]):_vm._e()]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }