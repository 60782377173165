<template>
    <transition name="fade" appear>
        <div class="compare-calendar-card"
            :id='"compare-card-" + eventSlot.index'
            @click="staticClickHandler"
            v-touch:longtap="openCompare"
            v-bind:class="{
                'selected'      : isSelected,
                'compare'       : compareIsOpen,
                'open-compare-animation' : compareAnimate && !compareIsOpen ,
                'close-compare-animation': !compareAnimate && compareIsOpen ,
            }"
            v-bind:style="{
                top: `${getPosition()}px`,
                height: `${slotHeight}px`,
            }">   
            <div class="varnish"></div>

            <div class="middle__info time" v-if="!isSelected && !compareAnimate || isSelected && compareAnimate">
                {{time}}
            </div>
            <div class="big-info" v-if="isSelected && !compareAnimate">
                <div class="card__header">
                    <div class="slot__title" v-bind:style="{
                        'max-height': `${slotHeight > 35 ? slotHeight - 12 - 8 : 30}px` 
                    }">
                        {{eventSlot.title || `Занятие ${this.index}`}}
                    </div>
                    <div class="time">
                        <div>{{time}}</div>
                    </div>
                </div>
                <div class="info" v-if="slotHeight > 35">
                    <div class="trainer">
                        {{ eventSlot.state.insertIndex }}
                    </div>
                    <div v-if="eventSlot.settings.maxAttenders">
                        0/{{eventSlot.settings.maxAttenders}}
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Slot from '../../../scripts/slot'
import { bus } from '../EventBus'

export default {
    name: 'CompareCard',
    props: {
        eventSlot:{
            type: Slot,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        date(){
            return this.eventSlot.period.start.date
        },
        time(){
            let minute = this.date.getMinutes()
            let hour = this.date.getHours()

            if (minute < 10) minute = "0" + minute 

            return hour + ":" + minute
        },
        index(){
            return this.eventSlot.index
        },
        cellSize(){
            return this.$store.getters.tableCeilSize
        },
        workStart(){
            return this.$store.getters.calendarWorkStart
        },
        compareIsOpen(){
            return this.eventSlot.compare.isActive
        },
        compareAnimate(){
            return this.eventSlot.compare.animate
        },
        slotHeight(){
            let ceilSize = this.$store.getters.tableCeilSize 
            let height = this.eventSlot.period.duration / 60 * ceilSize.height
            if (this.hour != this.workStart.hour)
                height -= 1 
            return (height)
        },
    },
    data() {
        return {
        }   
    },
    methods: {
        openCompare(){
            if (!this.isSelected) return
            this.eventSlot.setCompareWithAnimation(!this.eventSlot.compare.isActive)
        },
        getPosition(){  
            
            let minute = this.date.getMinutes()
            let hour = this.date.getHours()

            let position = 
                hour + 
                minute / 60 - 
                this.workStart.hour - 
                this.workStart.minute / 60
            return position * this.cellSize.height + 1
        },

        staticClickHandler(){
            if (!this.isSelected){
                this.selectDay()
            }
        },
        selectDay() {
            if (this.isSelected === false) {
                bus.$emit('calendar', 'select-date', {
                    date: this.date
                });
            }
        },
    },
}
</script>

<style scoped>
.compare-calendar-card{
    
    position: absolute;
    width: 100%;
    height: 54px;
    cursor: pointer;
    font-size:10.5px;
    background: #99d6f9;
    outline: 1px solid grey;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.varnish{
    position: absolute;
    height: 100%;
    width: 100%;
}

.compare{
    width: 50%;
    right: 0;
    z-index: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
}

div::selection {
   background: transparent;
}
div::-moz-selection {
   background: transparent;
}
.big-info{
    padding: 4px 3px 4px 3px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card__header{
    text-decoration-color: currentColor;
    font-size:12px;
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.middle__info{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.selected{
    display: block;
    min-width: 50px;
}
.slot__title{
    text-decoration-color: currentColor;
    font-size:12px;
    display: flex;
    justify-content: space-between;
    height: 100%;
}
.title{
    text-overflow: ellipsis;
    overflow: hidden;
}
.trainer{
    float: right;
}
.info{
    display:table-cell;
    vertical-align:bottom;
}
.time{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    font-size:12px;
}

.time div {
    display: inline-block;
    width: fit-content
}

.close-compare-animation{
    animation: size-down 0.4s reverse;
}

.open-compare-animation{
    animation: size-down 0.4s;
}

@keyframes size-down {
  from {width: calc(100% - 1px); right: 0;}
  to   {width: 50%; right: 0;}
}
.day-select{
    background: rgb(201, 201, 245);
}

.selector{
    height: 12px;
    width: 12px;
    border: 1px solid black;
    border-radius: 10px;
}
</style>